.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown .value {
  font-size: 24px;
  font-weight: 600;
  color: white;
  background: rgb(48, 48, 48);
  background: linear-gradient(
    180deg,
    rgba(48, 48, 48, 1) 0%,
    rgba(116, 116, 116, 1) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 60px;
  height: 65px;
  border-top: 2px solid #707070;
  border-left: 2px solid #707070;
  border-right: 2px solid #707070;

  position: relative;
}

.countdown .value::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  z-index: 2;
  background-color: #535353;
}

.value::after {
  content: "";
  border-radius: 8px;
  position: absolute;
  left: 0px;
  width: 2px;
  height: 5px;
  z-index: 3;
  height: 20px;
  background-color: white;
}

.valueAfter {
  display: flex;
  align-items: center;
}

.valueAfter::after {
  content: "";
  position: absolute;
  right: 0px;
  width: 2px;
  height: 5px;
  border-radius: 8px;
  z-index: 3;
  height: 20px;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .countdown .value {
    font-size: 16px;
    font-weight: 200;
    color: white;
    background: rgb(48, 48, 48);
    background: linear-gradient(
      180deg,
      rgba(48, 48, 48, 1) 0%,
      rgba(116, 116, 116, 1) 100%
    );
  
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    border-top: 2px solid #707070;
    border-left: 2px solid #707070;
    border-right: 2px solid #707070;
  
    position: relative;
  }
}