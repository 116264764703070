@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Fonts */

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Regular.eot");
  src: local("Makro XM Regular"), local("MakroXM-Regular"),
    url("./assets/fonts/MakroXM-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Regular.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Regular.woff") format("woff"),
    url("./assets/fonts/MakroXM-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoKufiArabic";
  src: url("./assets/fonts/NotoKufiArabic-Regular.ttf");
  src: local("NotoKufiArabic-Regular"), local("NotoKufiArabic-Regular"),
    url("./assets/fonts/NotoKufiArabic-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Light.eot");
  src: local("Makro XM Light"), local("MakroXM-Light"),
    url("./assets/fonts/MakroXM-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Light.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Light.woff") format("woff"),
    url("./assets/fonts/MakroXM-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Italic.eot");
  src: local("Makro XM Italic"), local("MakroXM-Italic"),
    url("./assets/fonts/MakroXM-Italic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Italic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Italic.woff") format("woff"),
    url("./assets/fonts/MakroXM-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Bold.eot");
  src: local("Makro XM Bold"), local("MakroXM-Bold"),
    url("./assets/fonts/MakroXM-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Bold.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Bold.woff") format("woff"),
    url("./assets/fonts/MakroXM-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-BoldItalic.eot");
  src: local("Makro XM Bold Italic"), local("MakroXM-BoldItalic"),
    url("./assets/fonts/MakroXM-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-BoldItalic.woff") format("woff"),
    url("./assets/fonts/MakroXM-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Medium.eot");
  src: local("Makro XM Medium"), local("MakroXM-Medium"),
    url("./assets/fonts/MakroXM-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Medium.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Medium.woff") format("woff"),
    url("./assets/fonts/MakroXM-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-ExtraBoldItalic.eot");
  src: local("Makro XM ExtraBold Italic"), local("MakroXM-ExtraBoldItalic"),
    url("./assets/fonts/MakroXM-ExtraBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-ExtraBoldItalic.woff") format("woff"),
    url("./assets/fonts/MakroXM-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-Black.eot");
  src: local("Makro XM Black"), local("MakroXM-Black"),
    url("./assets/fonts/MakroXM-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/MakroXM-Black.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-Black.woff") format("woff"),
    url("./assets/fonts/MakroXM-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-BlackItalic.eot");
  src: local("Makro XM Black Italic"), local("MakroXM-BlackItalic"),
    url("./assets/fonts/MakroXM-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-BlackItalic.woff") format("woff"),
    url("./assets/fonts/MakroXM-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-ExtraBold.eot");
  src: local("Makro XM ExtraBold"), local("MakroXM-ExtraBold"),
    url("./assets/fonts/MakroXM-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-ExtraBold.woff") format("woff"),
    url("./assets/fonts/MakroXM-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-MediumItalic.eot");
  src: local("Makro XM Medium Italic"), local("MakroXM-MediumItalic"),
    url("./assets/fonts/MakroXM-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-MediumItalic.woff") format("woff"),
    url("./assets/fonts/MakroXM-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Makro XM";
  src: url("./assets/fonts/MakroXM-LightItalic.eot");
  src: local("Makro XM Light Italic"), local("MakroXM-LightItalic"),
    url("./assets/fonts/MakroXM-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MakroXM-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/MakroXM-LightItalic.woff") format("woff"),
    url("./assets/fonts/MakroXM-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

.starRatingWrap {
  display: inline-block;
  touch-action: none;
}

.simpleStarRating {
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  touch-action: none;
}

svg.star-svg {
  display: inline;
}
iframe {
  border-radius: 10px !important;
}

.swiper-pagination-bullet {
  background-color: black !important;
}

body::-webkit-scrollbar {
  height: 7px;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #000000aa;
}

body::-webkit-scrollbar-track-piece:end {
  background-color: #ebebeb;
  margin-right: 140px; /* adjust this to change the end point of the scrollbar */
}

body::-webkit-scrollbar-track-piece:start {
  background-color: #ebebeb;
  margin-left: 140px; /* adjust this to change the start point of the scrollbar */
}
.css-auhyjf{
  flex-wrap: wrap;
}