.scroll {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 25px 0px 25px 0px;
}

.scroll::-webkit-scrollbar {
  height: 7px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #000000aa;
}

.scroll::-webkit-scrollbar-track-piece:end {
  background-color: #ebebeb;
  margin-right: 140px; /* adjust this to change the end point of the scrollbar */
}

.scroll::-webkit-scrollbar-track-piece:start {
  background-color: #ebebeb;
  margin-left: 140px; /* adjust this to change the start point of the scrollbar */
}

@media only screen and (max-width: 600px) {
  .scroll {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 25px 0px 25px 0px;
  }
}
