.App {
    font-family: sans-serif;
    /* text-align: center; */
    /* height: 100vh; */
}
  
.App-map {
   height: 25%;
   width: 25%;
}
  