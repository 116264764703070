.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown .value {
  font-size: 24px;
  font-weight: 600;
  color: black;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 60px;
  height: 65px;

  position: relative;
}

.countdown .value::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  margin-top: 2px;
  z-index: 2;
  background-color: white;
}

.value::after {
  content: "";
  border-radius: 8px;
  position: absolute;
  left: 0px;
  width: 2px;
  z-index: 3;
  height: 5px;
  background-color: black;
}

.valueAfter {
  display: flex;
  align-items: center;
}

.valueAfter::after {
  content: "";
  position: absolute;
  right: 0px;
  width: 2px;
  border-radius: 8px;
  z-index: 3;
  height: 5px;
  background-color: black;
}
.productDetailsReview {
  @apply flex flex-wrap flex-row-reverse gap-[50px] h-[222px] overflow-y-auto;
}

.productDetailsDescription {
  @apply flex flex-col gap-[10px] h-[222px] overflow-y-auto;
}

.productDetailsDescription::-webkit-scrollbar,
.productDetailsReview::-webkit-scrollbar {
  width: 5px;
}

.productDetailsDescription::-webkit-scrollbar-thumb,
.productDetailsReview::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
  cursor: pointer;
}

.productDetailsDescription::-webkit-scrollbar-track,
.productDetailsReview::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.productDetailsDescription::-webkit-scrollbar-thumb:hover,
.productDetailsReview::-webkit-scrollbar-thumb:hover {
  background-color: #000000aa;
  cursor: pointer;
}
