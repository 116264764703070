/* styles.css */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

  @keyframes rotateFadeIn {
    0% {
      opacity: 0;
      transform: rotate(-45deg);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }

  @keyframes slideGrowIn {
    0% {
      opacity: 0;
      transform: translateX(300px) scale(0.5);
    }
    100% {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }
  @keyframes colorFadeIn {
    0% {
      opacity: 0;
      background-color: rgba(255, 255, 255, 0);
    }
    100% {
      opacity: 1;
      background-color: rgba(255, 0, 0, 1);
    }
  }
  @keyframes zoomRotateFadeIn {
    0% {
      opacity: 0;
      transform: scale(0) rotate(-45deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }

  @keyframes slideBounceIn {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    50% {
      transform: translateX(40px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fadeInUp {
    animation: fadeInUp 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  
  .fadeInLeft {
    animation: fadeInLeft 2.0s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  .rotateFadeIn {
    animation: rotateFadeIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  .slideGrowIn {
    animation: slideGrowIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }
  .colorFadeIn {
    animation: colorFadeIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .zoomRotateFadeIn {
    animation: zoomRotateFadeIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  .slideBounceIn {
    animation: slideBounceIn 2.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }