#sub-category {
  visibility: unset !important;
  position: absolute !important;
  background-color: #fff !important;
  top: 160px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 10 !important;
}
*:focus {
  outline: none !important;
  box-shadow: none !important;
}
