.productsContainer {
  @apply w-[100%] overflow-x-auto mt-[20px] flex gap-[67px];
}

.productsContainer {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 50px 0px;
}

.productsContainer::-webkit-scrollbar {
  height: 7px;
}

.productsContainer::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
}

.productsContainer::-webkit-scrollbar-thumb:hover {
  background-color: #000000aa;
}

@media only screen and (max-width: 600px) {
  .productsContainer {
    @apply w-[100%] overflow-x-auto mt-[10px] flex gap-[33px];
  }
  .productsContainer {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 25px 0px;
  }
}
